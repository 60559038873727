<template>
  <div class="pb-2">
    <h4 class="text-center font-medium-5">
      <!--      {{ $t('Payment Terms List') }}-->
    </h4>
    <l-draggable-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      trash-list-path="settings-payment-terms-trash-list"
      :create-page-url="{ name: 'settings-payment-terms-create' }"
      :can-create="ACCESS_ABILITY_FOR_SYSTEM_PAYMENT_TERMS"
      :can-show-trash-list="ACCESS_ABILITY_FOR_SYSTEM_PAYMENT_TERMS"
      :is-pagination-able="true"
      :is-searchable="false"
      :table-config-options="getTableConfigOptions()"
      @onChoose="onChoose"
    >
      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <Can
            :i="ACCESS_ABILITY_FOR_SYSTEM_PAYMENT_TERMS.action"
            :a="ACCESS_ABILITY_FOR_SYSTEM_PAYMENT_TERMS.subject"
          >
            <feather-icon
              v-b-tooltip.noninteractive.hover.bottom
              :title="$t('View')"
              icon="LStockEyeIcon"
              class="cursor-pointer mr-1"
              size="32"
              @click="gotoNewPage({ name: 'settings-payment-terms-update', params: { id: data.id } }, $event)"
            />
            <feather-icon
              v-b-tooltip.noninteractive.hover.bottom
              :title="$t('Set inactive')"
              icon="LTrashIconKits"
              size="32"
              class="cursor-pointer mr-1"
              @click="setInactive(data)"
            />
          </Can>
        </div>
      </template>
      <template #cell(label)="{ data }">
        <div class="text-nowrap">
          {{ data.label }}
        </div>
      </template>
      <template #cell(is_active)="{ data }">
        <div class="text-nowrap text-center">
          {{ data.is_active ? 'Active' : 'Inactive' }}
        </div>
      </template>
      <template #cell(is_default)="{data}">
        <div
          :id="data.id"
          class="d-flex align-items-center justify-content-center w-100"
          style="width: max-content; height: 20px;  "
          :style="{pointerEvents: isLoadingOfSetDisabled ? 'none':'all'}"
          @click="setDefault(data)"
        >
          <div class="cursor-pointer">
            <b-form-radio
              :id="data.id.toString()"
              :checked="data.is_default"
              style="pointer-events: none"
              :value="true"
              :disabled="isLoadingOfSetDisabled"
            />
            <b-spinner
              v-if="isSettling(data.id)"
              style="margin-bottom: 4px;"
              class="ml-1"
              small
              label="Small Spinner"
              type="grow"
              variant="success"
            />
          </div>
        </div>
      </template>
    </l-draggable-table>
  </div>
</template>

<script>

import LDraggableTable from '@/views/components/LDraggableTable.vue'
import { BFormRadio, BSpinner, VBTooltip } from 'bootstrap-vue'
import tableConfig from '../paymentTermsConfig'

export default {
  name: 'PaymentTermsList',
  components: {
    BFormRadio,
    BSpinner,
    LDraggableTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
    }
  },
  methods: {
    refetchData() {
      this.$refs.lTableRef.fetchTableItemsList()
    },
    isSettling(id) {
      return this.isLoadingOfSetDisabled && this.idOfSettledDefault === id
    },
    setDefault(data) {
      const id = Number(data.id)
      this.isLoadingOfSetDisabled = true
      this.idOfSettledDefault = id
      this.sendNotification(
        this,
        id,
        `${this.MODULE_NAME}/setDefault`,
      )
        .then(() => {
          this.refetchData()
          this.isLoadingOfSetDisabled = false
        })
    },
    getTableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/getActiveList`,
      }
    },
    setInactive(data) {
      this.confirmNotification(this, { id: data.id, is_active: false }, `${this.MODULE_NAME}/update`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' }, false)
        .then(() => {
          this.refetchData()
        }).catch(err => {
          this.toastErrorNotification(this, err.response?.data.message, err.response.status)
        })
    },
    update(data) {
      this.$router.push({ name: 'settings-payment-terms-update', params: { id: data.id } })
    },
    onChoose() {
      this.sendNotification(
        this, { sort_order: this.$refs.lTableRef.$data.items.map(item => item.id) },
        `${this.MODULE_NAME}/setPosition`,
      ).then(() => {
        this.isLoadingOfSetDisabled = false
      })
    },
  },
  setup() {
    const MODULE_NAME = 'settings-payment-terms'
    const { fields, tableColumns, ACCESS_ABILITY_FOR_SYSTEM_PAYMENT_TERMS } = tableConfig()
    return {
      fields,
      tableColumns,
      MODULE_NAME,
      ACCESS_ABILITY_FOR_SYSTEM_PAYMENT_TERMS,
    }
  },
}
</script>
